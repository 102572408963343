/**
 * `FileUtils` is a namespace that provides utility functions for file-related operations. 
 * It includes functions to extract file names and extensions from URLs, 
 * check file types (such as image or video), convert files to data URLs, 
 * and retrieve appropriate CSS classes for file icons based on file extensions.
 * @namespace FileUtils
 */
namespace FileUtils {
    /**
     * Retrieves the extension from the provided file name.
     * @param {string} pFileName - The name of the file.
     * @returns {string | undefined} The file extension, or undefined if there is no extension.
     */
    export function getExtensionFromFileName(pFileName:string): string | undefined {
        if(pFileName == null){
            return undefined;
        }

        if (pFileName?.indexOf(".") === -1) {
            return pFileName.toLowerCase();
        }

        const re = /(?:\.([^.]+))?$/;

        if (re.exec(pFileName)?.[1] == undefined) {
            return undefined;
        }

        return re.exec(pFileName)?.[1].toLowerCase();
    }

    /**
     * Retrieves the file name from a given URL.
     * @param {string} pUrl - The URL from which to extract the file name.
     * @returns {string} The extracted file name.
     */
    export function getFileNameFromUrl(pUrl: string): string {
        const { pathname, searchParams } = new URL(pUrl);

        const fileName = searchParams.get('file-name');

        if (fileName) {
            return decodeURIComponent(fileName);
        }

        return decodeURIComponent(pathname.substring(pathname.lastIndexOf('/') + 1));
    }

    /**
     * Provides a list of video file extensions.
     * @returns {Array<string>} An array of common video file extensions.
     */
    export function getVideoFileExtensions(): Array<string> {
        return ['mp4', 'avi', 'wmv', 'mpg', 'mov','webm'];
    }

    /**
     * Provides a list of image file extensions.
     * @returns {Array<string>} An array of common image file extensions.
     */
    export function getImageFileExtensions(): Array<string> {
        return ['png', 'jpg', 'jpeg', 'gif', 'webp'];
    }

    /**
     * Provides a list of file extensions (types of files) that the backend can convert to pdf.
     * @returns {Array<string>} An array of file extensions that can be converted to pdf.
     */
    export function getPdfSupportedExtensions(): Array<string> {
        return [
            // Word
            "doc", "docx", "rtf", "dot", "dotx", "dotm", "docm", "odt", "ott",
            // Excel
            "xls", "xlsx", "xlsb", "xlt", "xltx", "xltm", "xlsm", "ods",
            // Slides
            "ppt", "pptx", "pps", "pot", "ppsx", "pptm", "ppsm", "potx", "potm",
            // Image
            "jpeg2000", "jpeg", "bmp", "tiff", "gif", "png", "psd", "dicom", "djvu", "dng", "odg", "eps", "jpg",
            // Email
            "msg", "pst", "ost", "oft", "eml", "emlx", "mbox",
            // Text
            "txt", "csv",
            // CAD
            "dxf", "cad", "dwg", "dwt", "plt", "cf2", "pcl", "hpgl", "dgn", "stl", "iges",
        ];
    }

    /**
     * Checks whether the provided file name is for an image file.
     * @param {string} pFileName - The name of the file to check.
     * @returns {boolean} True if the file is an image file, false otherwise.
     */
    export function isImage(pFileName: string): boolean {
        const vExtension = getExtensionFromFileName(pFileName);

        if (vExtension === undefined) {
            return false;
        }

        return getImageFileExtensions().includes(vExtension);
    }

    /**
     * Checks whether the provided file name is for a video file.
     * @param {string} pFileName - The name of the file to check.
     * @returns {boolean} True if the file is a video file, false otherwise.
     */
    export function isVideo(pFileName:string){
        const vExtension = getExtensionFromFileName(pFileName);

        if (vExtension === undefined) {
            return false;
        }

        return getVideoFileExtensions().includes(vExtension);
    }

    /**
     * Checks whether the provided file name is for a file that can be converted to a pdf.
     * @param {string} pFileName - The name of the file to check.
     * @returns {boolean} True if the file can be converted to pdf, false otherwise.
     */
    export function isPdfConversionSupported(pFileName: string) {
        const vExtension = getExtensionFromFileName(pFileName);

        if (vExtension === undefined) {
            return false;
        }

        return getPdfSupportedExtensions().includes(vExtension);
    }

    /**
     * Converts a file to a data URL.
     * @param {File} pFile - The file to convert to a data URL.
     * @returns {Promise<string | ArrayBuffer | null>} A promise that resolves with the data URL, or rejects on error.
     */
    export function fileToDataUrl(pFile: File): Promise<string | ArrayBuffer | null> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = function () {
                resolve(reader.result);
            };

            reader.readAsDataURL(pFile);
        });
    }

    /**
     * Provides the appropriate Bootstrap CSS classes for a file icon based on the file's extension.
     * @param {string} extension - The file extension.
     * @param {boolean} [addTextColor=true] - Whether to add a text color class.
     * @returns {Array<string>} An array of CSS classes for the file icon.
     */
    export function getFileIconClasses(extension: string, addTextColor: boolean = true): Array<string> {
        switch (extension?.toLowerCase()) {
            case "pdf":
                return ['bi-file-earmark-pdf-fill', ...(addTextColor ? ['text-warning'] : [])];
            case "html":
                return ['bi-file-earmark-code-fill', ...(addTextColor ? ['text-primary'] : [])];
            case "doc":
            case "docx":
                return ['bi-file-earmark-word-fill', ...(addTextColor ? ['text-primary'] : [])];
            case "txt":
                return ['bi-file-earmark-text-fill', ...(addTextColor ? ['text-primary'] : [])];
            case "rtf":
                return ['bi-file-earmark-richtext-fill', ...(addTextColor ? ['text-primary'] : [])];
            case "xls":
            case "xlsm":
            case "xlsx":
                return ['bi-file-earmark-excel-fill', ...(addTextColor ? ['text-success'] : [])];
            case "ppt":
            case "pptx":
                return ['bi-file-earmark-ppt-fill', ...(addTextColor ? ['text-warning'] : [])];
            case "jpg":
            case "jpeg":
            case "bmp":
            case "png":
            case "gif":
                return ['bi-file-earmark-image-fill', ...(addTextColor ? ['text-warning'] : [])];
            case "msg":
                return ['bi bi-file-earmark-richtext-fill', ...(addTextColor ? ['text-primary']: [])];
            case 'mp4':
            case 'avi':
            case 'vmw':
            case 'mpg':
            case 'mov':
                return ['bi-file-earmark-play-fill', ...(addTextColor ? ['text-primary'] : [])];
            case "zip":
            case "rar":
                return ['bi-file-earmark-zip-fill', ...(addTextColor ? ['text-danger'] : [])];
        }

        return ['bi-file-earmark-fill', ...(addTextColor ? ['text-black']: [])]
    }
    export function isMedia(fileName:string) {
        const vExtension = getExtensionFromFileName(fileName);
        if(vExtension)
        return isImage(vExtension) || isVideo(vExtension);

        return false;
    }
    export  function safeEscapeFileName(pFileName:string){
        if(pFileName.indexOf("+") > -1 ){
            return null;
        }else{
            return encodeURIComponent(pFileName)
        }
    }
}

export default FileUtils;

export const {
    fileToDataUrl,
    getExtensionFromFileName,
    getFileIconClasses,
    getFileNameFromUrl,
    getImageFileExtensions,
    getVideoFileExtensions,
    getPdfSupportedExtensions,
    isImage,
    isVideo,
    isPdfConversionSupported,
    isMedia,
    safeEscapeFileName
} = FileUtils;
